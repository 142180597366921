/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApiService } from '@classes/apiClasses';
import { IListResponse, IResponse } from '@interfaces/services';
import { ICasinoSubscription, IUserSubscription } from '@interfaces/userSubscription';
import { Get, Post } from 'lib/axiosWrapper';
import { IServiceReturn } from './common';
import { ICasinoSchema } from 'appRedux/models/casinoModels';

export interface ICheckoutResponse {
  id: string;
}

export interface ICasinoLinkedAccount {
  appUserPk: string;
  casinoId: number;
  playerId: string;
}

export interface ILinkedAccounts extends IServiceReturn {
  user: {
    linkedAccounts: Array<ICasinoLinkedAccount>;
  };
}

export interface IUser extends IServiceReturn {
  user: {
    info: IUserInfo;
    subscriptions: IUserSubscription[];
    preference: object;
    linkedAccounts: Array<ICasinoLinkedAccount>;
    hasAccess: boolean;
    timeLeft: number;
  };
}

export interface ICasinoAccessInfo {
  hasAccess: boolean;
  timeLeft: number;
}

export interface ICasinoAccess {
  [casinoId: number]: ICasinoAccessInfo;
}

export interface ICasinoSchemaWithLastVisit extends ICasinoSchema {
  lastVisit?: string;
}
export interface IUserCasinosList {
  otherCasinos: Array<ICasinoSchemaWithLastVisit>;
  primaryCasino: ICasinoSchema;
  recentCasinos: Array<ICasinoSchemaWithLastVisit>;
}
export interface IUserInfoAndAccess extends IServiceReturn {
  user: {
    info: IUserInfo;
    subscription: IUserSubscription;
    linkedAccounts: Array<ICasinoLinkedAccount>;
    casinoAccess: ICasinoAccess;
    userCasinoList: IUserCasinosList;
  };
}

interface UserData {
  cognitoUserName: string;
  userDetails: IUserInfo;
  aliasInitial: string;
  aliasInitial2: string;
  aliasName: string;
}

export interface IUserSignup extends IServiceReturn {
  code: number;
  data: UserData;
  message: string;
  errorCode?: number;
  errorMsg?: string;
}

export interface ILinkedAccount {
  casinoId: number;
  casino_fk: number;
  createDate: number;
  playerId: number;
  birthday: string;
}

export interface ILoginResponse {
  user: IUserInfo;
  lastLogin: ILastLogin;
  token?: string;
}

export interface ILastLogin {
  last_login_timestamp: string;
  last_login_timestamp_counter: number;
}

export interface IAliasInfo {
  aliasInitial: string;
  aliasInitial2: string;
  aliasName: string;
}

export interface IUserInfo extends IAliasInfo {
  createDate: number;
  cognitoUserName: string;
  appUserPk: string;
  isPublicVisible: boolean;
  hasCompletedOnboarding?: boolean;
  phone: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  playerId?: number;
  birthday?: string;
  casinos?: number[]; // TODO: casino chooser will use this to display what casinos are visible to user
  linkedAccounts?: ILinkedAccount[];
}

export interface ISubscriptionInfo {
  appUserFk: string;
  subscriptionPlanFk: number;
  plan?: string;
  startDate: string;
  endDate: string;
}

export interface IGetMe extends IServiceReturn {
  user: {
    info: object;
    subscription: object;
    preference: object;
  };
}

export interface IUpdateMeResponse extends IServiceReturn {
  userInfo: Partial<IUserInfo>;
}

export class AuthService extends ApiService {
  constructor() {
    super('auth');
  }

  trackPreference(filters: any) {
    return Post<ICheckoutResponse>(`${this.baseRoutePath}/trackPreference`, { filters }, {}, true);
  }

  Login() {
    return Post<IResponse<ILoginResponse>>(`${this.baseRoutePath}/Login`, {}, {}, true, true);
  }

  GetUserInfo(casinoIds: number[]) {
    return Get<IUserInfoAndAccess>(`${this.baseRoutePath}/GetUserInfo`, { casinoIds }, {}, true);
  }

  GetMe(casinoId: number) {
    return Get<IUser>(`${this.baseRoutePath}/GetMe`, { casinoId }, {}, true);
  }

  UpdateMe(userInfo: Partial<IUserInfo>) {
    return Post<IUpdateMeResponse>(`${this.baseRoutePath}/UpdateMe`, { userInfo }, {}, true);
  }

  GetUserSubscriptionByCasinoId() {
    return Get<ICasinoSubscription>(
      `${this.baseRoutePath}/GetUserSubscriptionByCasinoId`,
      {},
      {},
      true
    );
  }

  GetUserSubscriptions() {
    return Get<IListResponse<IUserSubscription>>(
      `${this.baseRoutePath}/GetUserSubscriptions`,
      {},
      {},
      true
    );
  }

  GetLinkedAccounts(appUserFk: string) {
    return Get<ILinkedAccounts>(`${this.baseRoutePath}/GetLinkedAccounts`, { appUserFk }, {}, true);
  }

  CreateAccount(phoneNumber: string, password: string) {
    return Post<IUserSignup>(
      `${this.baseRoutePath}/CreateAccount`,
      { phoneNumber, password },
      {},
      false
    );
  }

  UpdateUserSubscription(userSubscription: ISubscriptionInfo) {
    return Post<ICheckoutResponse>(
      `${this.baseRoutePath}/UpdateUserSubscription`,
      userSubscription,
      {},
      true
    );
  }
}
