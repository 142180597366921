/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable security/detect-object-injection */
import { FC } from 'react';
import 'moment';
import 'chartjs-adapter-moment';
import { config, getTimeFrameDetails, getTimeCharacter } from '../../config/config';
import { parseValue } from '../../common/include';
import UpgradeNow from '../UpgradeNow/UpgradeNow';
import SmartLoading from '../SmartLoading';
import { useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { IGraphGameData, ISlotBucketData, ISlotGraphData } from 'appRedux/models/casinoModels';
import BarChartComponent from './BarChartComponent';
interface StateProps {
  timeframe: string;
  insight: string;
  slotPlayData: ISlotGraphData[] | undefined;
  slotSplitData: IGraphGameData[];
  onClickUpgradeNow?: () => void;
  isLoading?: boolean;
  bucketData: ISlotBucketData | undefined;
}

const getChartLabel = (bucketData: ISlotBucketData, insight: string, timeframe: string) => {
  const { label } = config.hotSlotsLabels[insight];
  const totalValue = bucketData.jackpot_value || 0;
  const numberOfItems = bucketData.jackpots;
  const transformedTimeframeLabel = timeframe;
  let transformedLabel = label;

  switch (insight) {
    case 'jackpot_value':
      transformedLabel = numberOfItems === 1 ? 'Jackpot' : label;
      return totalValue ? (
        <div>
          <strong>{numberOfItems}</strong> {transformedLabel}{' '}
          <strong> {transformedTimeframeLabel}</strong>{' '}
          <strong> = {parseValue(totalValue, 'jackpot_value')}</strong>
        </div>
      ) : (
        <span>No data in the selected time frame</span>
      );
    default:
      return (
        <>
          {transformedLabel}{' '}
          <strong className="transformed-label">{transformedTimeframeLabel}</strong>
        </>
      );
  }
};

type SlotDataChartProps = StateProps;

const SlotDataChart: FC<SlotDataChartProps> = (props) => {
  const {
    timeframe,
    insight,
    slotPlayData,
    slotSplitData,
    onClickUpgradeNow,
    isLoading,
    bucketData,
  } = props;
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const timeFrameDetail = getTimeFrameDetails(timeframe);
  const insightLabel = config.hotSlotsLabels[insight]?.label || '';
  const isGraphShown = slotPlayData && slotPlayData.length > 0;
  const slotDataValue =
    insight === 'jackpot_value'
      ? slotSplitData.length > 0
        ? slotSplitData
        : slotPlayData
      : slotPlayData;
  if (isLoading) {
    return (
      <div className="slot-chart-box">
        <div className="no-jackpots-container">
          <SmartLoading loading customCls="graph-loader" />
        </div>
      </div>
    );
  }

  if (!isGraphShown) {
    return (
      <div className="slot-chart-box">
        <div className="no-jackpots-container slot-chart-header graph-overlay">
          No data in the selected time frame
          {!isProUser && <UpgradeNow onClick={onClickUpgradeNow} />}
        </div>
      </div>
    );
  }

  return (
    <div className="slot-chart-box">
      {bucketData && isGraphShown && (
        <>
          <div className="slot-chart-header">
            <div className="game-inactive slj-age chart-header-container">
              {getChartLabel(bucketData, insight, getTimeCharacter(timeFrameDetail?.value))}
            </div>
            <div className="chart-yaxis-title">{insightLabel}</div>
          </div>
          <div className="total-insight-value">
            {slotDataValue && (
              <BarChartComponent
                slotData={slotDataValue}
                insight={insight}
                timeframe={timeframe}
                backgroundColor={'#fff'}
                hasNoJackpotsToDisplay={false}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SlotDataChart;
