export const prodConfig = {
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_wixKjr4v5',
    userPoolWebClientId: '449iunsf137rtqlqp2s3799pr0',
    mandatorySignIn: true,
    oauth: {
      domain: 'slotcheck.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'https://my.slotcheck.com',
      redirectSignOut: 'https://my.slotcheck.com',
      responseType: 'token',
    },
  }
};
