import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { playerTypeEnum } from 'common/commonEnum';
import { ICasinoReport } from 'appRedux/models/casinoModels';
import { InsightValue } from './types';
import { config } from 'config/config';
import {
  ATTEMPS_LIMIT_EXCEEDED_MESSAGE,
  INVALID_OTP_CODE_MESSAGE,
  INVALID_OTP_CODE_MESSAGE2,
  MUST_HAVE_LOWERCASE_CHAR_MESSAGE,
  MUST_HAVE_SYMBOL_CHAR_MESSAGE,
  MUST_HAVE_UPPERCASE_CHAR_MESSAGE,
  SHORT_PASSWORD_MESSAGE,
} from './include';
library.add(fas);

export { toPercentage } from './toPercentage';

/** 1 being the coldest slot and 6 being the hottests slot */
export enum slotHotnessScale {
  Six = 6,
  Five = 5,
  Four = 4,
  Three = 3,
  Two = 2,
  One = 1,
}
export const heatLegendAllInsight: number[] = [6, 5, 4, 3, 2, 1];
export const heatLegendJackpots: number[] = [6, 5, 4];

export enum OrderByEnum {
  Asc = 'asc',
  Desc = 'desc',
}
export type OrderValueType = 'jackpot_value_order' | 'alias_desc_order' | 'alias_asc_order';
export type MapType = keyof typeof MapTypeEnum;
export enum MapTypeEnum {
  slotReportMap = 'slotReportMap',
  individualReportMap = 'individualReportMap',
}

export enum OrderValueEnum {
  JackpotOrder = 'jackpot_value_order',
  AliasDescOrder = 'alias_desc_order',
  AliasAscOrder = 'alias_asc_order',
}
export type ListReportType = 'standard' | 'myplay' | 'jackpot' | 'favorites';
export enum ListReportEnum {
  Standard = 'standard',
  MyPlay = 'myplay',
  MyJackpot = 'jackpot',
  Favorites = 'favorites',
}

export enum FilterTypeEnum {
  leaderboard = 'leaderboard',
  jackpot = 'jackpot',
  myplay = 'myplay',
  graphfilter = 'graphfilter',
  mapfilter = 'mapfilter',
  main = 'main',
  hottestSlots = 'hottestSlots',
}
export type IFilterType = keyof typeof FilterTypeEnum;

export type ToolTipPositionType = keyof typeof ToolTipPositionEnum;
export enum ToolTipPositionEnum {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export type ToolTipAlignType = keyof typeof ToolTipAlignEnum;
export enum ToolTipAlignEnum {
  start = 'start',
  center = 'center',
  end = 'end',
}
export type InsightType = {
  id: number;
  value: InsightValue;
  field: string;
  label: string;
  short_name: string;
  trendLabel: string;
  hotColdLabel: string;
  insightFieldsSelectorLabel: string;
  color: string;
  backgroundColor: string;
  enableDarkBg: boolean;
  className: string;
  description: string; // To be added
  display_order: number;
  insightId: number;
  icon: string; // To be added
  playerTypeAccess: playerTypeEnum[];
  highVoltilityLabel?: string; // Optional property
  lowVoltilityLabel?: string; // Optional property
};

export type SnapshotMetric = {
  id: number;
  field: string;
  value: string;
  label: string;
  snapshot_label: string;
  short_name: string;
  description: string;
};

export type SnapshotMetricGroup = {
  display_order: number;
  className: string;
  items: SnapshotMetric[];
};

export type SlotMetricsData = {
  snapShotArr: SnapshotMetricGroup[];
};

export type pinArrayType = {
  label: string;
  id: number;
  index: number;
};

export const getSubscriptionNameAndLogo = (isProUser: boolean) => {
  if (isProUser) {
    return {
      name: 'Slot Pro',
      logo: 'assets/images/SlotPro.png',
    };
  } else {
    return {
      name: 'Slot Check',
      logo: 'assets/images/Check.png',
    };
  }
};

export const getNearestTimeFrame = (timeFrame: string) => {
  let fromDate: any;
  const toDate = moment();
  switch (timeFrame) {
    case 'hours1':
      fromDate = moment(toDate).subtract(1, 'hour');
      break;
    case 'hours4':
      fromDate = moment(toDate).subtract(4, 'hour');
      break;
    case 'hours8':
      fromDate = moment(toDate).subtract(8, 'hour');
      break;
    case 'hours24':
      fromDate = moment(toDate).subtract(24, 'hour');
      break;
    case 'yesterday1':
      fromDate = moment(toDate).subtract(1, 'day');
      break;
    case 'today1':
      fromDate = moment(toDate).subtract(24, 'hour');
      break;
    case 'weeks1':
      fromDate = moment(toDate).subtract(1, 'week').subtract(1, 'day');
      break;
    case 'months1':
      fromDate = moment(toDate).subtract(1, 'month').subtract(1, 'day');
      break;
    default:
      fromDate = moment(toDate).subtract(1, 'month').subtract(1, 'day');
      break;
  }
  if (timeFrame === 'today1' || timeFrame === 'yesterday1') {
    return fromDate.startOf('day').format();
  }
  if (timeFrame === 'sinceLastJackpot' || timeFrame === 'all') {
    return;
  }
  return fromDate;
};

export const getLastUpdatedDate = (datatime: any) => moment(datatime).format('MMM D, h:mma');

export const displayTimeFrame = (timeFrame: string) => {
  let fromDate: any;
  let toDate = moment();
  // Round down to nearest 5 minute bucket
  const numMinutes = Math.floor(toDate.minutes() / 5) * 5;
  toDate = toDate.minutes(numMinutes);
  switch (timeFrame) {
    case 'hours1':
      fromDate = moment(toDate).subtract(1, 'hour');
      break;
    case 'hours4':
      fromDate = moment(toDate).subtract(4, 'hour');
      break;
    case 'hours8':
      fromDate = moment(toDate).subtract(8, 'hour');
      break;
    case 'hours24':
      fromDate = moment(toDate).subtract(24, 'hour');
      break;
    case 'yesterday1':
      fromDate = moment(toDate).subtract(1, 'day').startOf('day');
      toDate = moment(toDate).subtract(1, 'day').endOf('day');
      break;
    case 'today1':
      fromDate = moment(toDate).startOf('day');
      break;
    case 'weeks1':
      fromDate = moment(toDate).subtract(1, 'week');
      break;
    case 'months1':
      fromDate = moment(toDate).subtract(1, 'month');
      break;

    default:
      fromDate = moment(toDate).subtract(1, 'month');
      break;
  }

  if (timeFrame === 'sinceLastJackpot' || timeFrame === 'all') {
    return;
  }
  // if (
  //   fromDate.month() === toDate.month() &&
  //   fromDate.date() === toDate.date()
  // ) {
  //   return fromDate.format('MMM D, h:mma') + ' - ' + toDate.format('h:mma');
  // }

  return toDate.format('MMM D, h:mma');
};

export function getDeviceOrientation(): 'portrait' | 'landscape' {
  if (window.screen.orientation) {
    const orientationType: OrientationType = window.screen.orientation.type;
    if (orientationType === 'landscape-primary' || orientationType === 'landscape-secondary') {
      return 'landscape';
    } else {
      return 'portrait';
    }
  } else {
    return 'portrait';
  }
}

export const transformPhoneInput = (inputValue: string) => {
  if (!inputValue) return '';

  const strippedInputValue = inputValue.replace(/-/g, '');
  const hasOnlyNumbers = /^\d+$/.test(strippedInputValue);

  if (!hasOnlyNumbers) return;

  const firstPart = strippedInputValue.slice(0, 3);
  const secondPart = strippedInputValue.slice(3, 6);
  const thirdPart = strippedInputValue.slice(6, 10);
  return `${firstPart}${secondPart ? `-${secondPart}` : ''}${thirdPart ? `-${thirdPart}` : ''}`;
};

export const getOrderAndHeatKey = (index: number, direction: OrderByEnum) => {
  return {
    order: index + 1,
    heatKey: direction === OrderByEnum.Asc ? slotHotnessScale.One : slotHotnessScale.Six,
  };
};
export const getTopAndBottom = (filteredArray: ICasinoReport[], insightKey: InsightValue) => {
  const orderData = filteredArray.sort((a, b) => b[insightKey] - a[insightKey]);
  const top10 = orderData.slice(0, 10);
  const bottom10 = orderData.slice(-10);
  return { top10, bottom10 };
};

export const setHeatKeyForAllItems = (
  slots: ICasinoReport[],
  number: slotHotnessScale
): ICasinoReport[] => {
  slots.forEach((slot) => {
    slot.heatKey = number;
  });
  return slots;
};

export const checkProUser = (subscriptionStatus: string, currentPeriodEnd: number): boolean => {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  return subscriptionStatus === 'active' && currentPeriodEnd > currentTimestamp;
};

export const isTextFinite = (text: string): boolean => {
  const number = parseInt(text, 10);
  return !isNaN(number) && isFinite(number);
};

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getTimeFrameInfo = (timeFrame: string) =>
  config.allTimeFrames.filter((timeframe) => timeframe.value === timeFrame)[0];

export const getResetPasswordErrorMessage = (awsErrorMessage: string) => {
  switch (awsErrorMessage) {
    case INVALID_OTP_CODE_MESSAGE:
    case INVALID_OTP_CODE_MESSAGE2:
      return 'Incorrect OTP code.';
    case SHORT_PASSWORD_MESSAGE:
    case MUST_HAVE_LOWERCASE_CHAR_MESSAGE:
    case MUST_HAVE_UPPERCASE_CHAR_MESSAGE:
    case MUST_HAVE_SYMBOL_CHAR_MESSAGE:
      return 'Please make sure your new password is at least 8 characters long and it has at least one lowercase letter, one uppercase letter, and a symbol character.';
    case ATTEMPS_LIMIT_EXCEEDED_MESSAGE:
      return "You've exceeded attempts limit. Please try again later.";
    default:
      return 'Something went wrong.';
  }
};
